import React, { useState } from "react"
import { Layout, Head, Breadcrumbs } from "@common"
import { SheetsApi } from "@api"
import { useStaticQuery, graphql, Link } from "gatsby"

import is from "is_js"

import {
  diagnose__wrapper,
  diagnose__wrapper__loader,
  option__wrapper,
} from "./styles/diagnose.module.scss"

const SingleOption = ({ el, onChange, checked }) => {
  return (
    <div className={option__wrapper}>
      <input
        checked={checked}
        onChange={onChange || ""}
        type="checkbox"
        name={el}
        value={el || ""}
      ></input>
      <label>{el || ""}</label>
    </div>
  )
}
const DiagnoseSelector = () => {
  const [state, setState] = useState({
    answer: [],
    email: "",
    msg: "",
    imBusy: false,
    checked: null,
  })
  const question = "Powiedz Djinnowi w czym potrzebujesz wsparcia"

  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(uri: "diagnoza") {
          ACF_form_questions {
            formquestions {
              questions
            }
          }
        }
      }
    }
  `)
  const { formquestions } = WP.pageBy.ACF_form_questions
  const answers = formquestions.map(el => el.questions)

  const handleSelect = event => {
    let diagnoseList = state.answer
    let checked = event.target.checked
    let selectedOption = event.target.value
    if (checked) {
      setState({
        ...state,
        email: state.email,
        answer: [...state.answer, selectedOption],
      })
    } else {
      let index = diagnoseList.indexOf(selectedOption)
      if (index > -1) {
        diagnoseList.splice(index, 1)
        setState({
          email: state.email,
          answer: diagnoseList,
        })
      }
    }
  }

  const sendResult = () => {
    if (is.email(state.email) === true) {
      setState({
        imBusy: true,
        checked: false,
      })
      SheetsApi.addAnswer(state.email, state.answer).then(data =>
        setState({ ...state, msg: data.msg, answer: [], email: "" })
      )
    }
  }

  return (
    <div className={diagnose__wrapper}>
      <h5>{question}</h5>
      {answers.map((el, k) => {
        return (
          <SingleOption
            checked={state.checked}
            el={el}
            key={k}
            onChange={el => handleSelect(el)}
          />
        )
      })}
      <input
        type="email"
        placeholder="Twój email"
        value={state.email || ""}
        onChange={ev => {
          setState({
            ...state,
            email: ev.target.value,
          })
        }}
      />
      <small>
        Udzielając odpowiedzi w ankiecie, oświadczasz że chcesz otrzymywać
        spersonalizowane informacje dotyczące kuracji leczniczych Twoich
        dolegliwości!!!
      </small>

      <div className="row justify-content-center">
        {state.msg == "" ? <h5>&nbsp;</h5> : <h5>{state.msg}</h5>}
      </div>

      <div className="row justify-content-center">
        {!state.imBusy ? (
          <button onClick={() => sendResult()}>Wyślij</button>
        ) : (
          <div className={diagnose__wrapper__loader}>
            <div className="spinner-border"></div>
          </div>
        )}
      </div>
    </div>
  )
}

const Diagnose = ({ pageContext }) => {
  const { page } = pageContext

  return (
    <Layout title={page.title}>
      <Head
        seo={pageContext.page.seo}
        siteMetaData={pageContext.page.siteMetaData}
      />
      <div className="page">
        <div className="page-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <Breadcrumbs
                  elements={[
                    {
                      label: page.title,
                      url: `/${page.slug}/`,
                    },
                  ]}
                />
                <DiagnoseSelector />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Diagnose
